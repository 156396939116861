@import "@fontsource/roboto";

$primary: #e95c0F;
$light: #ebeee7;
$black: #1d1d1d;
$btn-border-radius-sm: 15px;
$btn-border-radius: 10px;
$btn-border-radius-lg: 15px;
$modal-content-border-radius: 15px;

@import "~bootstrap/scss/bootstrap";

.bg-black {
    background-color: $black !important;
}

body {
    font-family: "Roboto";
    color: $black;
}

.navbar-dark {
    .navbar-nav {
        .nav-link {
            color: rgba(255, 255, 255, 0.8);
            font-size: 18px;
        }
    }
    .navbar-toggler {
        border: none;
        &:focus {
            box-shadow: none;
        }
    }
}
.navbar-brand {
    img {
        width: 125px;
        /*vertical-align: initial;*/
    }
}

footer {
    padding: 50px;
    background: $black;
    color: $primary;
    a {        
        margin: 0px 10px;
        color: #eee;
        text-decoration: none;
        &:hover {
            color: $primary;
            text-decoration: underline;
        }
    }
    img {
        max-width: 100%;
        width: 450px;
    }
    hr {
        margin: 50px 0px;
        background-color: $secondary;
    }
}

#intro {
    min-height: 100vh;
    background-image: url('/public/assets/img/intro-background-2.jpeg');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    background-attachment:fixed;
    text-shadow: 0px 0px 10px $black;

    a {
        margin-top: 50px;
        display: inline-block;
        color: #fff;
        &:hover {
            color: $primary;
        }
    }
}

#der-verein {
    svg {
        color: $secondary;
        margin-right: 5px;
    }
}

section {
    padding: 50px 0px;
    &.content {
        /*box-shadow: inset 30px 40px 50px #f5f5f5;*/
        h2, h3, h4 {
            font-weight: 400;
            border-left: 5px solid $primary;
            margin-bottom: 30px;
            padding: 0px 0px 0px 15px;
            line-height: 1;
        }
        h2 {
            font-size: 44px;
        }
    }
}

#modal-impressum {
    h4, p {
        margin-bottom: 30px;
    }
}

.modal-header, .modal-footer {
    border: 0px;
}

/* Animation */
@keyframes fadeInUp {
    from {
        transform: translate3d(0, 40px, 0);
    }
    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}
@-webkit-keyframes fadeInUp {
    from {
        transform: translate3d(0, 40px, 0);
    }
    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}
.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both;
}

.animatedFadeInUp {
    opacity: 0;
}

.fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
}